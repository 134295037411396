import store from '@/store';

const isLogged = (to, from, next) => {
  // const partner = store.getters['partner/partner'];
  // if (!partner.hasMyAccountAccess) {
  //   next({ name: 'LandingPage' });
  //   return;
  // }

  const logged = store.getters['user/logged'];
  if (!logged && from.name === 'UserLogout') {
    next({ name: 'UserLogin' });
    return;
  }

  if (
    !logged
    && ['UserLogin', 'User'].indexOf(to.name) > -1
    && from.name !== 'UserLogout'
  ) {
    next({ name: 'UserLogout' });
    return;
  }

  if ((to.name === 'User' || to.name === 'UserLogin') && logged) {
    next({ name: 'UserAccount' });
    return;
  }

  next();
};

const guard = (to, from, next) => {
  const progressTracker = store.getters['ui/progressTracker'];
  const { modality } = store.getters['scheduling/appointment'];

  if (progressTracker.some((step) => step.route.logged === to.name)) {
    const stepTo = progressTracker.find((step) => step.route.logged === to.name);

    if (stepTo.step > 1) {
      const stepFrom = progressTracker.find((step) => step.step === stepTo.step - 1);

      if (stepFrom.isCompleted) {
        next();
        return;
      }

      next({ name: stepFrom.route.logged });
    }
    next();
    return;
  }

  if (
    from.name === 'UserAccount'
    && (to.name === 'UserScheduling' || to.name === 'UserSchedulingSpecialty')
    && progressTracker[0].isCompleted
    && modality === 'telemedicine'
  ) {
    next({ name: 'UserSchedulingDate' });
    return;
  }

  if (
    from.name === 'UserAccount'
    && (to.name === 'UserScheduling' || to.name === 'UserSchedulingSpecialty')
    && modality === 'presential'
  ) {
    next();
    return;
  }

  if (
    to.name === 'UserScheduling'
    || to.name === 'UserSchedulingSpecialty'
    || to.name === 'UserAccount'
  ) {
    store.dispatch('scheduling/setAppointment', { specialty: null });
    next();
    return;
  }

  if (
    to.name === 'UserSchedulingSummary'
    && !progressTracker.every((step) => step.isCompleted)
  ) {
    next({ name: progressTracker[0].route.unlogged });
    return;
  }

  next();
};

const routes = [
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/user/index.vue'),
    beforeEnter: isLogged,
    children: [
      {
        path: 'login',
        name: 'UserLogin',
        component: () => import('../views/user/Login.vue'),
      },
      {
        path: 'logout',
        name: 'UserLogout',
        component: () => import('../views/user/Logout.vue'),
      },
      {
        path: 'register',
        name: 'UserRegister',
        component: () => import('../views/user/Register.vue'),
      },
      {
        path: 'reset-password/:token?',
        name: 'UserResetPassword',
        component: () => import('../views/user/ResetPassword.vue'),
      },
      {
        path: 'account',
        name: 'UserAccount',
        component: () => import('../views/user/Account.vue'),
        beforeEnter: isLogged,
      },
      {
        path: 'appointments',
        name: 'UserAppointments',
        beforeEnter: isLogged,
        component: () => import('../views/user/Appointments.vue'),
        redirect: { name: 'UserAppointmentsList' },
        children: [
          {
            path: '/',
            name: 'UserAppointmentsList',
            component: () => import('../components/user/medicalHistory/appointments.vue'),
            beforeEnter: isLogged,
          },
          {
            path: 'details/:id?',
            name: 'UserAppointmentDetails',
            component: () => import('../components/user/appointments/appointmentDetails.vue'),
            beforeEnter: isLogged,
          },
        ],
      },
      {
        path: 'profile',
        name: 'UserProfile',
        component: () => import('../views/user/Profile.vue'),
        beforeEnter: isLogged,
        redirect: { name: 'UserProfileData' },
        children: [
          {
            path: 'data',
            name: 'UserProfileData',
            component: () => import('../components/user/profile/userData.vue'),
            beforeEnter: isLogged,
          },
          {
            path: 'address',
            name: 'UserProfileAddress',
            component: () => import('../components/user/profile/userAddress.vue'),
            beforeEnter: isLogged,
          },
          {
            path: 'password',
            name: 'UserProfilePassword',
            component: () => import('../components/user/profile/userPassword.vue'),
            beforeEnter: isLogged,
          },
        ],
      },
      {
        path: 'payment',
        name: 'UserPayment',
        component: () => import('../views/user/Payment.vue'),
        beforeEnter: isLogged,
      },
      {
        path: 'scheduling',
        name: 'UserScheduling',
        component: () => import('../views/user/Scheduling.vue'),
        redirect: { name: 'UserSchedulingSpecialty' },
        beforeEnter: isLogged,
        children: [
          {
            path: 'specialty',
            name: 'UserSchedulingSpecialty',
            component: () => import('../components/appointmentScheduling/AppointmentSpecialty.vue'),
            beforeEnter: guard,
          },

          {
            path: 'date',
            name: 'UserSchedulingDate',
            component: () => import('../components/appointmentScheduling/AppointmentDate.vue'),
            beforeEnter: guard,
          },

          {
            path: 'payment/:id?',
            name: 'UserSchedulingCheckout',
            component: () => import('../components/appointmentScheduling/AppointmentCheckout.vue'),
            beforeEnter: guard,
          },

          {
            path: 'summary',
            name: 'UserSchedulingSummary',
            component: () => import('../components/appointmentScheduling/AppointmentSummary.vue'),
            beforeEnter: guard,
          },
        ],
      },
      {
        path: 'my-doctors',
        name: 'MyDoctors',
        component: () => import('../views/user/MyDoctors.vue'),
        beforeEnter: isLogged,
      },
      {
        path: 'medical-history',
        name: 'UserMedicalHistory',
        component: () => import('../views/user/MedicalHistory.vue'),
        beforeEnter: isLogged,
      },
      {
        path: 'pharmacy-discount',
        name: 'UserPharmacyDiscount',
        component: () => import('../views/user/PharmacyDiscount.vue'),
        beforeEnter: isLogged,
      },
      {
        path: 'doctor-agenda/:doctorId?',
        name: 'UserDoctorAgenda',
        component: () => import('../views/user/DoctorAgenda.vue'),
        beforeEnter: isLogged,
      },
    ],
  },
];

export default routes;
