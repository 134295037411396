<template functional>
  <progress
    class="progress-bar"
    max="100"
  />
</template>

<script>
export default {
  name: 'ProgressBar',
};
</script>
