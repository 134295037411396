<template>
  <button
    :disabled="disabled"
    :class="[`button--${color}`, `button--${width}`]"
    class="button"
    @click.prevent="$emit('click')"
  >
    <template v-if="icon">
      <span class="button__icon">
        <img :src="icon">
      </span>
    </template>

    <template v-if="label">
      <span>
        {{ label }}
      </span>
    </template>
  </button>
</template>

<script>
export default {
  name: 'Button',

  props: {
    color: {
      type: String,
      required: false,
      default: () => 'primary',
    },

    width: {
      type: String,
      required: false,
      default: () => 'full',
    },

    icon: {
      type: String,
      required: false,
      default: () => null,
    },

    label: {
      type: String,
      required: false,
      default: () => 'Título do botão',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
};
</script>
