import axios from 'axios';
import router from '@/router/';
import store from '@/store/';

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401 && err.response.config.headers.Authorization) {
      const errorObj = {
        type: 'alert',
        title: 'Erro',
        text: 'Seu acesso expirou. Por favor, faça o login novamente.',
        confirmText: 'Fechar',
        size: 'sm',
        fn: () => store.dispatch('ui/closeModalDialog'),
      };

      store.dispatch('ui/openModalDialog', errorObj);
      router.push({ name: 'UserLogout' });

      return Promise.reject();
    }

    return Promise.reject(err);
  },
);

export default instance;
