const appointment = {
  id: null,
  uuid: null,
  modality: null,
  specialty: null,
  date: null,
  time: null,
  creationDate: null,
};

const doctor = {
  id: null,
  uuid: null,
  name: null,
  crm: null,
  uf: null,
};

const office = {
  id: null,
  uuid: null,
  address: null,
};

const patient = {
  email: null,
  name: null,
  cpf: null,
  gender: null,
  contactPhone: null,
  birthdate: null,
  wasUnlogged: false,
};

const address = {
  cep: null,
  street: null,
  number: null,
  complement: null,
  district: null,
  city: null,
  uf: null,
  latitude: null,
  longitude: null,
  search: null,
};

const payment = {
  id: null,
  method: { name: null },
  price: null,
  installments: 1,
  data: null,
  validations: null,
  voucher: null,
};

const setState = (state, payload) => {
  Object.keys(payload).forEach((key) => {
    if ({}.hasOwnProperty.call(state, key)) {
      state[key] = payload[key];
    }
  });
};

const state = () => ({
  appointment: { ...appointment },

  doctor: { ...doctor },

  office: { ...office },

  patient: { ...patient },

  address: { ...address },

  payment: { ...payment },

  reservationStartTime: null,

  savedAppointment: null,

  loginStatus: false,
});

const getters = {
  appointment: (state) => state.appointment,

  doctor: (state) => state.doctor,

  office: (state) => state.office,

  patient: (state) => state.patient,

  address: (state) => state.address,

  payment: (state) => state.payment,

  reservationStartTime: (state) => state.reservationStartTime,

  savedAppointment: (state) => state.savedAppointment,

  loginStatus: (state) => state.loginStatus,
};

const actions = {
  setAppointment(context, commit) {
    context.commit('mutateSetAppointment', commit);
  },

  resetAppointment(context) {
    context.commit('mutateResetAppointment');
  },

  setDoctor(context, commit) {
    context.commit('mutateSetDoctor', commit);
  },

  resetDoctor(context) {
    context.commit('mutateResetDoctor');
  },

  setOffice(context, commit) {
    context.commit('mutateSetOffice', commit);
  },

  resetOffice(context) {
    context.commit('mutateResetOffice');
  },

  setPatient(context, commit) {
    context.commit('mutateSetPatient', commit);
  },

  resetPatient(context) {
    context.commit('mutateResetPatient');
  },

  setAddress(context, commit) {
    context.commit('mutateSetAddress', commit);
  },

  resetAddress(context) {
    context.commit('mutateResetAddress');
  },

  setPayment(context, commit) {
    context.commit('mutateSetPayment', commit);
  },

  resetPayment(context) {
    context.commit('mutateResetPayment');
  },

  setReservationStartTime(context, commit) {
    context.commit('mutateSetReservationStartTime', commit);
  },

  resetReservationStartTime(context) {
    context.commit('mutateResetReservationStartTime');
  },

  setSavedAppointment(context) {
    context.commit('mutateSetSavedAppointment');
  },

  resetSavedAppointment(context) {
    context.commit('mutateResetSavedAppointment');
  },

  restartAppointment(context) {
    context.commit('mutateRestartAppointment');
    context.commit('mutateResetSavedAppointment');
  },

  setLoginStatus(context, commit) {
    context.commit('mutateSetLoginStatus', commit);
  },

  resetLoginStatus(context, commit) {
    context.commit('mutateResetLoginStatus', commit);
  },

  resetScheduling(context) {
    context.commit('mutateResetAppointment');
    context.commit('mutateResetDoctor');
    context.commit('mutateResetOffice');
    context.commit('mutateResetPatient');
    context.commit('mutateResetAddress');
    context.commit('mutateResetPayment');
    context.commit('mutateResetReservationStartTime');
    context.commit('mutateResetSavedAppointment');
    context.commit('mutateResetLoginStatus');
  },
};

const mutations = {
  mutateSetAppointment(state, payload = {}) {
    setState(state.appointment, payload);
  },

  mutateResetAppointment(state) {
    state.appointment = { ...appointment };
  },

  mutateSetDoctor(state, payload = {}) {
    setState(state.doctor, payload);
  },

  mutateResetDoctor(state) {
    state.doctor = { ...doctor };
  },

  mutateSetOffice(state, payload = {}) {
    setState(state.office, payload);
  },

  mutateResetOffice(state) {
    state.office = { ...office };
  },

  mutateSetPatient(state, payload = {}) {
    setState(state.patient, payload);
  },

  mutateResetPatient(state) {
    state.patient = { ...patient };
  },

  mutateSetAddress(state, payload = {}) {
    setState(state.address, payload);
  },

  mutateResetAddress(state) {
    state.address = { ...address };
  },

  mutateSetPayment(state, payload) {
    setState(state.payment, payload);
  },

  mutateResetPayment(state) {
    state.payment = { ...payment };
  },

  mutateSetReservationStartTime(state, payload) {
    state.reservationStartTime = payload;
  },

  mutateResetReservationStartTime(state) {
    state.reservationStartTime = null;
  },

  mutateSetSavedAppointment(state) {
    state.savedAppointment = {
      appointment: { ...state.appointment },
      doctor: { ...state.doctor },
      office: { ...state.office },
      patient: { ...state.patient },
      address: { ...state.address },
      payment: { ...state.payment },
      reservationStartTime: state.reservationStartTime,
    };
  },

  mutateResetSavedAppointment(state) {
    state.savedAppointment = null;
  },

  mutateRestartAppointment(state) {
    Object.keys(state.savedAppointment).forEach((key) => {
      setState(state[key], state.savedAppointment[key]);
    });
  },

  mutateSetLoginStatus(state, payload) {
    state.loginStatus = payload;
  },

  mutateResetLoginStatus(state) {
    state.loginStatus = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
