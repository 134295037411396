const theme = 'default';

const state = () => ({
  partner: {
    name: 'Dr. Conecta',
    uuid: '798ed3e4-f9e5-11ea-815e-024233f2e2e5',
    theme,
    contactEmail: 'atendimento@email.com.br',
    medicalNetwork: 'DRCONECTA',
    logo: {
      header: `${theme}_header.png`,
      footer: `${theme}_footer.png`,
    },
    modalities: [
      {
        title: 'Telemedicina',
        value: 'telemedicine',
      },
      {
        title: 'Presencial',
        value: 'presential',
      },
    ],
  },

  paymentMethods: [],

  medicalSpecialties: [],
});

const getters = {
  partner: (state) => state.partner,

  paymentMethods: (state) => state.paymentMethods,

  medicalSpecialties: (state) => state.medicalSpecialties,
};

const actions = {
  setPartner(context, partner) {
    context.commit('mutatePartner', partner);
  },

  setPaymentMethods(context, commit) {
    context.commit('mutatePaymentMethods', commit);
  },

  setMedicalSpecialties(context, commit) {
    context.commit('mutateMedicalSpecialties', commit);
  },
};

const mutations = {
  mutatePartner(state, payload) {
    state.partner = { ...payload };
  },

  mutatePaymentMethods(state, payload) {
    state.paymentMethods = payload;
  },

  mutateMedicalSpecialties(state, payload) {
    state.medicalSpecialties = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
