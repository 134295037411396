const state = () => ({
  session: {
    token: null,
    username: null,
    roles: [],
  },
  user: {},
});

const getters = {
  logged: (state) => state.session.token,
  session: (state) => state.session,
  headers: (state) => ({
    headers: {
      Authorization: `Bearer ${state.session.token}`,
      'Content-Type': 'application/json',
    },
  }),
  user: (state) => state.user,
};

const actions = {
  setSession(context, commit) {
    context.commit('mutateSession', commit);
  },

  setUser(context, commit) {
    context.commit('mutateUser', commit);
  },

  resetUser(context) {
    context.commit('mutateResetUser');
  },

  clearAuth(context) {
    context.commit('mutateResetUser');
    context.commit('mutateResetSession');
  },
};

const mutations = {
  mutateSession(state, payload = {}) {
    for (const prop in state.session) {
      if (payload[prop]) {
        state.session[prop] = payload[prop];
      }
    }
  },

  mutateResetSession(state) {
    for (const prop in state.session) {
      state.session[prop] = null;
    }
  },

  mutateUser(state, payload = {}) {
    state.user = payload;
  },

  mutateResetUser(state) {
    for (const field in state.user) {
      state.user[field] = null;
    }
  },
};

const persisted = [
  'user/mutateSession', 'user/mutateUser', 'user/mutateResetuser', 'user/mutateResetSession',
];

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  persisted,
};
