<template>
  <transition
    name="fade"
    mode="in-out"
  >
    <modal
      v-if="modalDialog.active"
      :class="`modal-dialog--${this.modalDialog.size}`"
      class="modal-dialog modal-dialog--alert"
      @close="close"
    >
      <template v-slot:title>
        {{ modalDialog.title }}
      </template>

      <template v-slot:body>
        <p
          v-html="modalDialog.text"
          class="modal-dialog__text"
        />
      </template>

      <template v-slot:footer>
        <div class="modal-dialog__footer">
          <div
            v-if="modalDialog.type === 'confirmation'"
            class="modal-dialog__col"
          >
            <ui-button
              color="cancel"
              :label="modalDialog.cancelText"
              @click="close"
            />
          </div>

          <div class="modal-dialog__col">
            <ui-button
              color="confirm"
              :label="modalDialog.confirmText"
              @click="modalDialog.fn"
            />
          </div>
        </div>
      </template>
    </modal>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/ui/Modal.vue';
import UiButton from '@/components/ui/Button.vue';

export default {
  name: 'ModalDialog',

  components: {
    Modal,
    UiButton,
  },

  computed: {
    ...mapGetters('ui', ['modalDialog']),
  },

  methods: {
    ...mapActions('ui', ['closeModalDialog']),

    close() {
      if (this.modalDialog.cancelFn && typeof this.modalDialog.cancelFn === 'function') {
        this.modalDialog.cancelFn();
        return;
      }

      this.closeModalDialog();
    },
  },
};
</script>
