const state = () => ({
  patient: {
    name: null,
    email: null,
    emailConfirmation: null,
    gender: null,
    cpf: null,
    phoneNumber: null,
    birthdate: null,
    cep: null,
    number: null,
    complement: null,
    address: null,
  },
});

const getters = {
  patient: (state) => state.patient,
};

const actions = {
  setPatient(context, commit) {
    context.commit('mutatePatient', commit);
  },

  setPatientAddress(context, commit) {
    context.commit('mutatePatientAddress', commit);
  },

  resetPatient(context) {
    context.commit('mutateResetPatient');
  },
};

const mutations = {
  mutatePatient(state, payload = {}) {
    Object.keys(payload).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(state.patient, key)) {
        state.patient[key] = payload[key];
      }
    });
  },

  mutatePatientAddress(state, payload) {
    state.patient.address = payload;
  },

  mutateResetPatient(state) {
    state.patient.name = null;
    state.patient.email = null;
    state.patient.emailConfirmation = null;
    state.patient.gender = null;
    state.patient.cpf = null;
    state.patient.phoneNumber = null;
    state.patient.birthdate = null;
    state.patient.cep = null;
    state.patient.number = null;
    state.patient.complement = null;
    state.patient.address = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
