function removeBreakSpace(str) {
  return str[0]
    .replace(/(\s+){2,}/gmi, ' ')
    .replace(/(\n+)|(\r+)/gmi, '');
}

const paymentMethods = [
  {
    name: 'creditCard',
    value: 'CARTÃO DE CRÉDITO',
    label: 'Cartão de crédito',
    icon: 'creditCard',
    component: 'creditCard',
    helperText: removeBreakSpace`Ao selecionar pagamento via cartão de crédito,
      você poderá agendar sua consulta para o mesmo dia, caso
      existam horários disponíveis.`,
  },

  {
    name: 'boleto',
    value: 'BOLETO',
    label: 'Boleto Bancário',
    icon: 'boleto',
    component: 'boleto',
    helperText: removeBreakSpace`Ao selecionar o pagamento via boleto bancário,
      é necessário aguardar até três dias úteis para a confirmação
      do pagamento. Neste caso, você poderá agendar suas consultas
      para daqui três dias.`,
  },

  {
    name: 'pix',
    value: 'PIX',
    label: 'PIX',
    icon: 'pix',
    component: 'pix',
    helperText: removeBreakSpace`Ao selecionar pagamento via PIX, você poderá
      agendar sua consulta para o mesmo dia, caso existam horários disponíveis.`,
  },

  {
    name: 'prepaid',
    value: 'HEALTHPLACE PRÉ PAGO',
    label: 'Benefício',
    icon: 'pix',
    component: 'prepaid',
    helperText: removeBreakSpace`Ao selecionar pagamento via benefício [partnerName],
      você poderá agendar sua consulta para o mesmo dia, caso existam horários disponíveis
      e desde que você tenha direito ao benefício.`,
  },

  {
    name: 'voucher',
    value: 'HEALTHPLACE PRÉ PAGO VOUCHER',
    label: 'Voucher',
    icon: 'voucher',
    component: 'voucher',
    helperText: removeBreakSpace`Ao selecionar pagamento via voucher,
      você poderá agendar sua consulta para o mesmo dia, caso existam horários disponíveis
      e desde que você tenha direito ao benefício.`,
  },
];

export default paymentMethods;
