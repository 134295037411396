import Vue from 'vue';
import Vuex from 'vuex';

import VuexPersistence from 'vuex-persist';
import scheduling from './scheduling';
import partner from './partner';
import patient from './patient';
import user from './user';
import ui from './ui';

Vue.use(Vuex);

const persistedMutations = [...user.persisted];

const vuexPersist = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ user: state.user }),
  filter: (mutation) => persistedMutations.indexOf(mutation.type) > -1,
});

export const plugins = [
  vuexPersist.plugin,
];

export default new Vuex.Store({
  plugins,
  modules: {
    scheduling,
    partner,
    patient,
    user,
    ui,
  },
});
