<template>
  <transition name="fade">
    <main
      v-if="isLoaded"
      class="health-place"
    >
      <transition
        mode="out-in"
        name="fade"
      >
        <progress-bar v-if="isProgressBar" />
      </transition>

      <router-view />

      <transition
        mode="out-in"
        name="fade"
      >
        <modal-dialog v-if="modalDialog.active" />
      </transition>
    </main>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import methods from '@/data/paymentMethods/paymentMethods';
import partner from '@/api/partner/partner';
import medicalSpecialties from '@/mixins/medicalSpecialties';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import ModalDialog from '@/components/ui/ModalDialog.vue';

export default {
  name: 'HealthPlace',

  mixins: [medicalSpecialties],

  components: {
    ProgressBar,
    ModalDialog,
  },

  data() {
    return {
      isLoaded: false,
      showPrepaid: true,
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('ui', ['isProgressBar', 'modalDialog']),
    ...mapGetters('patient', ['patient']),
  },

  created() {
    const domain = /localhost/gi.test(document.domain)
      ? 'healthplace.drconecta.cm2tech.com.br'
      : document.domain.replace('www', '');

    partner(domain)
      .getPartner()
      .then((data) => this.storePartner(data))
      .then((theme) => this.getTheme(theme))
      .then((data) => this.setCssVariables(data.style, data.newStyle))
      .then(() => this.fetchMedicalSpecialties())
      .catch((err) => console.log('err is', err))
      .finally(() => {
        this.isLoaded = true;
      });
  },

  methods: {
    ...mapActions('partner', ['setPartner', 'setPaymentMethods']),

    getTheme(theme = 'default') {
      return new Promise((resolve) => {
        fetch(
          `/static/themes/${theme}.json`,
          { method: 'GET', headers: { 'Content-Type': 'application/json' } },
        )
          .then((res) => res.json())
          .then(resolve)
          .catch(() => {
            fetch(
              '/static/themes/default.json',
              { method: 'GET', headers: { 'Content-Type': 'application/json' } },
            )
              .then((res) => res.json())
              .then(resolve);
          });
      });
    },

    setCssVariables(vars = [], newStyle = {}) {
      vars.forEach((style) => document.documentElement.style.setProperty(`--${style.var}`, style.value));

      if (newStyle) {
        for (const type in newStyle) {
          for (const prop in newStyle[type]) {
            document.documentElement.style.setProperty(`--${type}-${prop}`, newStyle[type][prop]);
          }
        }
      }
    },

    storePartner(data = null) {
      if (data) {
        const {
          id,
          'medical_network': medicalNetwork,
          'template_style_guide': theme,
          'contact_email': contactEmail,
          'has_iframe_project': hasIframe,
          'has_my_account_access': hasMyAccountAccess,
          'health_place_partner_user_api_integration_partners': hasIntegrationPartners,
          'health_place_partner_health_place_payment_methods': paymentMethods,
          type,
          uuid,
          name,
        } = data;

        const logo = {
          header: `${theme}_header.png`,
          footer: `${theme}_footer.png`,
        };

        // Set appointment modalities
        const telemedicine = { title: 'Telemedicina', value: 'telemedicine' };
        const presential = { title: 'Presencial', value: 'presential' };
        const modalities = [];

        if (/telemedicine/gi.test(type)) {
          modalities.push(telemedicine);
        }

        if (/presential/gi.test(type)) {
          modalities.push(presential);
        }

        const partner = {
          id,
          name,
          uuid,
          logo,
          theme,
          hasIframe,
          contactEmail,
          medicalNetwork,
          modalities,
          hasMyAccountAccess,
          hasIntegrationPartners: (
            hasIntegrationPartners
            && Array.isArray(hasIntegrationPartners)
            && hasIntegrationPartners.length > 0
          )
            ? hasIntegrationPartners[0]['is_active']
            : false,
        };

        document.title = name;
        this.setFavicon(theme);
        this.setPartner(partner);
        this.setPaymentMethods(this.getPaymentMethods(paymentMethods));

        return String(theme).toLowerCase();
      }

      document.title = 'Demonstração Dr. Conecta';
      this.setFavicon(this.partner.theme);

      return this.partner.theme;
    },

    setFavicon(partner) {
      const head = document.querySelector('head');
      const favicon = document.createElement('link');

      const { protocol, hostname, port } = document.location;
      const url = `${protocol}//${hostname}${
        port
          ? `:${document.location.port}`
          : ''
      }`;

      favicon.setAttribute('rel', 'shortcut icon');
      favicon.setAttribute('href', `${url}/static/favicon/${partner}.ico`);
      head.appendChild(favicon);
    },

    getPaymentMethods(options = []) {
      const paymentMethods = methods
        .filter(
          (method) => options
            .filter((option) => Boolean(option['is_active']))
            .map((option) => String(option['health_place_payment_method'].name).toLowerCase())
            .includes(String(method.value).toLowerCase()),
        );

      return paymentMethods;
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/main";
</style>
