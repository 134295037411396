import Vue from 'vue';
import VueRouter from 'vue-router';

import landingPage from './landingPage';
import scheduling from './scheduling';
import appointmentDetails from './appointmentDetails';
import user from './user';

Vue.use(VueRouter);

const routes = [
  ...landingPage,
  ...scheduling,
  ...appointmentDetails,
  ...user,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
