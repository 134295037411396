<template>
  <div
    class="modal"
    v-scroll-lock="true"
  >
    <section
      class="modal__wrapper"
      :class="size"
    >
      <header class="modal__header">
        <h1 class="modal__title">
          <slot name="title" />
        </h1>

        <button
          class="modal__close"
          @click.prevent="$emit('close')"
        >
          <i class="icon icon-cross" />
        </button>
      </header>

      <article class="modal__body">
        <slot name="body" />
      </article>

      <footer class="modal__footer">
        <slot name="footer" />
      </footer>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    size: {
      type: String,
      default: () => 'w-full',
    },
  },
};
</script>
