import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VScrollLock from 'v-scroll-lock';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = process.env.NODE_ENV === 'production';

Vue.use(Vuelidate);
Vue.use(VScrollLock);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
