import { mapActions, mapGetters } from 'vuex';
import medicalSpecialties from '@/api/appointmentScheduling/medicalSpecialties';

export default {
  data() {
    return {
      ui: { isLoading: false },
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),
  },

  methods: {
    ...mapActions('ui', ['toggleProgressBar']),
    ...mapActions('partner', ['setMedicalSpecialties']),

    fetchMedicalSpecialties() {
      return new Promise((resolve, reject) => {
        this.ui.isLoading = true;
        this.toggleProgressBar();

        medicalSpecialties(
          this.partner.uuid,
          false,
          false,
          !!this.partner.modalities.find((modality) => modality.value === 'telemedicine'),
          !!this.partner.modalities.find((modality) => modality.value === 'presential'),
        )
          .getSpecialties()
          .then((res) => {
            this.setMedicalSpecialties(res);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          })
          .finally(() => {
            this.ui.isLoading = false;
            this.toggleProgressBar(false);
          });
      });
    },
  },
};
